import {
  handleQueryResolve
} from '../utils';

export default function (pharmName, ocp) {

    let attrs = [{
      param: 'pharmName',
      type: 'sql.VarChar(50)',
      value: '%' + pharmName + '%' // to support like
    }];
    let where = ['Users.UserName = @pharmName'];

    if (ocp) {
      attrs.push({
        param: 'ocp',
        type: 'sql.VarChar(50)',
        value: '%' + ocp + '%' // to support like
      });

      where.push('Users.PharmID LIKE @ocp');
    }

    return this.query(`
        SELECT
          Users.UserName,
          Users.PharmID
        FROM
          Fillware.dbo.Users
    `, attrs).then(handleQueryResolve);
  }




